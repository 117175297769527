import { useContext, useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Image } from "image-js";
import { AccountContext } from "../pages/Account";
import { SessionContext } from "../App";
import API from "../API";
import Utils from "../Utils";
import strings from "../strings";
import "./Settings.css";

function Settings() {
	const session = API.getSession();
	const adminID = session?.idAdmin ?? 0;
	const defaultImage = "/images/avatar.svg";
	const setLoggedIn = useContext(SessionContext);
	const fileInputRef = useRef();
	const nameRef = useRef();
	const emailRef = useRef();
	const passwordRef = useRef();
	const confirmPasswordRef = useRef();
	const [isLoading, setIsLoading] = useState(true);
	const [initialState, setInitialState] = useState({});
	const [formData, setFormData] = useState({});
	const [titleName, setTitleName] = useState("");
	const [image, setImage] = useState(null);
	const [alert, setAlert] = useState("");
	const [alertType, setAlertType] = useState("");
	const [errors, setErrors] = useState({});
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [setAccountContext, setAccountImage, setAccountName] = useContext(AccountContext);

	const logout = () => API.logout().then(() => setLoggedIn(false));

	const initialFocus = () => {
		if (nameRef.current) {
			nameRef.current.focus();
		}
	};

	const closeAlert = () => {
		setAlert("");
		setAlertType("");
		initialFocus();
	};

	const onChangeValue = (event) => {
		const {name, value} = event.target;
		setFormData((prevFormData) => ({...prevFormData, [name]: value}));
		setErrors({...errors, [name]: false})
	};

	const loadImage = (event) => {
		event.preventDefault();

		let file;
		if (event.target?.files) {
			file = event.target.files[0];
		} else if (event.dataTransfer?.files) {
			file = event.dataTransfer.files[0];
		}

		if (file) {
			const reader = new FileReader();

			reader.onloadend = async () => {
				const image = await Image.load(reader.result);

				const fixedSize = 500;
				let resizedImage;
				if (image.width > image.height) {
					const width = image.width * fixedSize / image.height;
					resizedImage = image.resize({width: width, height: fixedSize}).crop({x: (width - fixedSize) / 2, width: fixedSize});
				} else {
					const height = image.height * fixedSize / image.width;
					resizedImage = image.resize({width: fixedSize, height: height}).crop({y: (height - fixedSize) / 2, height: fixedSize});
				}

				setImage(resizedImage.toDataURL("image/jpeg", {useCanvas: true}));
				setFormData((prevFormData) => ({...prevFormData, image: file}));
			}

			reader.readAsDataURL(file);
		}
	};

	const openFileInput = () => fileInputRef.current.click();

	const removeImage = () => {
		setFormData((prevFormData) => ({...prevFormData, image: ""}));
		setImage(defaultImage);
	};

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
		passwordRef.current.focus();
	};

	const toggleShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
		confirmPasswordRef.current.focus();
	};

	const areDataSaved = (JSON.stringify(formData) === JSON.stringify(initialState));

	const formValidation = (formData) => {
		let errorMessages = {};
		let firstErrorField = null;

		if (formData.name.length === 0) {
			errorMessages.name = strings.requiredField;
			firstErrorField = nameRef;
		}

		if (formData.email.length === 0) {
			errorMessages.email = strings.requiredField;
			if (!firstErrorField) {
				firstErrorField = emailRef;
			}
		} else if (!Utils.isEmailValid(formData.email)) {
			errorMessages.email = strings.invalidEmailFormat;
			if (!firstErrorField) {
				firstErrorField = emailRef;
			}
		}

		if (formData.password.length > 0 && !Utils.isPasswordStrong(formData.password)) {
			errorMessages.password = strings.passwordIsTooWeak;
			if (!firstErrorField) {
				firstErrorField = passwordRef;
			}
		}

		if (confirmPasswordRef.current.value !== formData.password) {
			errorMessages.confirmPassword = strings.passwordsDontMatch;
			if (!firstErrorField) {
				firstErrorField = confirmPasswordRef;
			}
		}

		return {errorMessages, firstErrorField};
	};

	const save = (event) => {
		event.preventDefault();
		setAlert("");
		setAlertType("");

		const data = Utils.trimFields(formData);
		const {errorMessages, firstErrorField} = formValidation(data);

		if (Object.keys(errorMessages).length > 0) {
			(firstErrorField ? firstErrorField : nameRef).current.focus();
			setErrors(errorMessages);
		} else {
			const {image, ...restOfData} = data;
			const dataToSend = (data.image === initialState.image) ? restOfData : data; // Sends the image only when it has been changed.

			API.editAdmin(...Object.values(dataToSend))
				.then((result) => {
					if (result) {
						switch (result.statusCode) {
							case 200:
								setAccountName(data.name);
								setTitleName(data.name);

								const newState = {...data, password: ""};

								if (dataToSend.image !== undefined) { // If the image was changed.
									if (result.data.image) {
										setImage(result.data.image);
										setAccountImage(result.data.image);
										newState.image = result.data.image;
									} else {
										setImage(defaultImage);
										setAccountImage(defaultImage);
									}
								}

								setInitialState(newState);
								setFormData(newState);
								confirmPasswordRef.current.value = "";

								initialFocus();
								break;
							case 401:
								logout();
								break;
							case 403:
								setAlertType("danger");
								setAlert(strings.unauthorisedRequest);
								setFormData(data);
								break;
							case 400:
							case 404:
							case 422:
								setAlertType("warning");
								setAlert(strings.requestFailed);
								setFormData(data);
								break;
							case 409:
								setAlertType("warning");
								setAlert(strings.signupAlreadyRegisteredText);
								setFormData(data);
								break;
							case 500:
								setAlertType("danger");
								setAlert(strings.serverError);
								setFormData(data);
								break;
							default:
						}
					}
				});
		}
	};

	useEffect(() => {
		API.getAdmin(adminID)
			.then((result) => {
				switch (result.statusCode) {
					case 200:
						setTitleName(result.data.username);
						setInitialState({id: adminID, name: result.data.username, email: result.data.email, password: "", image: result.data.image ?? ""});
						setFormData({id: adminID, name: result.data.username, email: result.data.email, password: "", image: result.data.image ?? ""});
						setImage(result.data.image ?? defaultImage);
						setIsLoading(false);
						initialFocus();
						break;
					case 401:
					case 403:
					case 404:
						logout();
						break;
					case 500:
						setAlertType("danger");
						setAlert(strings.serverError);
						break;
					default:
				}
			});
	}, []);

	useEffect(() => {
		setAccountContext({currentPage: strings.settings, breadcrumbs: [
			{
				label: strings.uloi,
				url: "/"
			},
			{
				label: strings.settings
			}
		]});
	}, [setAccountContext]);

	useEffect(() => {
		if (!isLoading) {
			initialFocus();
		}
	}, [isLoading]);

	return (
		(isLoading) ?
			<Spinner animation="border" className="spinner" />
		:
			<Container id="settings-container">
				<Row>
					<Col id="user-name-title" className="user-select-none transparent-cursor text-center">
						{titleName}
					</Col>
				</Row>
				<Row className="mt-3 justify-content-center">
					<Col id="image-circle" className="col-9 col-sm-6 col-md-5 col-xl-4 col-xxl-3 text-center" onDrop={loadImage} onDragOver={(event) => event.preventDefault()}>
						<img alt="" src={image || defaultImage} crossOrigin="anonymous" />
					</Col>
				</Row>
				<Form onSubmit={save}>
					<Row className="mt-3 d-flex justify-content-center">
						<Col className="text-center">
							<Form.Control type="file" className="d-none" onChange={loadImage} ref={fileInputRef} accept="image/*" />
							<Button type="button" className="image-button" onClick={openFileInput}>{strings.change}</Button>
							<Button type="button" className="image-button ms-2" onClick={removeImage} disabled={(!image || image === defaultImage)}>{strings.remove}</Button>
						</Col>
					</Row>
					{
						(alert) &&
							<Row className="mt-4">
								<Col>
									<Alert variant={alertType} className="mb-0 user-select-none transparent-cursor" dismissible={true} onClose={closeAlert}>{alert}</Alert>
								</Col>
							</Row>
					}
					<Row className={`${(alert) ? "mt-2" : "mt-4"}`}>
						<Col className="col-12 col-md-6 me-0 me-md-4">
							<Form.Group>
								<Form.Label className="standard-label user-select-none transparent-cursor" htmlFor="settings-name">{strings.name}</Form.Label>
								<Form.Control type="text" id="settings-name" name="name" className={`${(errors.name) ? "error-border" : ""}`} placeholder={strings.name} ref={nameRef} value={formData.name ?? ""} onChange={onChangeValue} />
								{(errors.name) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="settings-name">{errors.name}</Form.Label>}
							</Form.Group>
						</Col>
						<Col className="mt-3 mt-md-0">
							<Form.Group>
								<Form.Label className="standard-label user-select-none transparent-cursor" htmlFor="settings-email">{strings.email}</Form.Label>
								<Form.Control type="text" id="settings-email" name="email" className={`${(errors.email) ? "error-border" : ""}`} placeholder={strings.email} ref={emailRef} value={formData.email ?? ""} onChange={onChangeValue} />
								{(errors.email) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="settings-email">{errors.email}</Form.Label>}
							</Form.Group>
						</Col>
					</Row>
					<Row className="mt-3">
						<Col className="col-12 col-md-6 me-0 me-md-4">
							<Form.Group>
								<Form.Label className="standard-label user-select-none transparent-cursor" htmlFor="settings-password">{strings.password}</Form.Label>
								<div className="password-field">
									<Form.Control type={showPassword ? "text" : "password"} id="settings-password" name="password" className={`${(errors.password) ? "error-border" : ""}`} autoComplete="new-password" placeholder={strings.password} ref={passwordRef} value={formData.password ?? ""} onChange={onChangeValue} />
									<img alt={showPassword ? strings.hide : strings.show} onClick={toggleShowPassword} src={showPassword ? "/images/eye_slashed.svg" : "/images/eye.svg"} />
									{
										(errors.password) &&
											<>
												<div id="password-tooltip" className="user-select-none transparent-cursor" dangerouslySetInnerHTML={{__html: strings.passwordRequirementsText}}></div>
												<Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="settings-password">{errors.password}</Form.Label>
											</>
									}
								</div>
							</Form.Group>
						</Col>
						<Col className="mt-3 mt-md-0">
							<Form.Group>
								<Form.Label className="standard-label user-select-none transparent-cursor" htmlFor="settings-confirm-password">{strings.confirmPassword}</Form.Label>
								<div id="password-field">
									<Form.Control type={showConfirmPassword ? "text" : "password"} id="settings-confirm-password" className={`${(errors.confirmPassword) ? "error-border" : ""}`} placeholder={strings.confirmPassword} ref={confirmPasswordRef} onChange={() => setErrors({...errors, confirmPassword: false})} />
									<img alt={showConfirmPassword ? strings.hide : strings.show} onClick={toggleShowConfirmPassword} src={showConfirmPassword ? "/images/eye_slashed.svg" : "/images/eye.svg"} />
									{(errors.confirmPassword) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="settings-confirm-password">{errors.confirmPassword}</Form.Label>}
								</div>
							</Form.Group>
						</Col>
					</Row>
					<Row className="col-12 col-md-6 my-4">
						<Col>
							<Button type="submit" id="save-button" className="standard-dark-button" disabled={areDataSaved}>
								<img alt="" src="/images/floppy_disk.svg" />
								<span className="align-middle ms-2">{strings.save}</span>
							</Button>
						</Col>
					</Row>
				</Form>
			</Container>
	);
}

export default Settings;
