import { useContext, useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import API from "../API";
// import ReCAPTCHA from "react-google-recaptcha";
import { SessionContext } from "../App";
import Utils from "../Utils";
import strings from "../strings";
import "./Login.css";

function Login() {
	const emailRef = useRef();
	const passwordRef = useRef();
	// const recaptchaRef = useRef();
	const [alert, setAlert] = useState("");
	const [alertType, setAlertType] = useState("");
	const [errors, setErrors] = useState({});
	const [showPassword, setShowPassword] = useState(false);
	const setLoggedIn = useContext(SessionContext);

	const closeAlert = () => {
		setAlert("");
		setAlertType("");

		if (emailRef.current) {
			emailRef.current.focus();
		}
	};

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
		passwordRef.current.focus();
	};

	const trimFields = () => {
		emailRef.current.value = emailRef.current.value.trim();
		passwordRef.current.value = passwordRef.current.value.trim();
	};

	const formValidation = () => {
		let errorMessages = {};
		let firstErrorField = null;

		if (emailRef.current.value.length === 0) {
			errorMessages.email = strings.requiredField;
			firstErrorField = emailRef;
		}

		if (emailRef.current.value.length > 0 && !Utils.isEmailValid(emailRef.current.value)) {
			errorMessages.email = strings.invalidEmailFormat;
			if (!firstErrorField) {
				firstErrorField = emailRef;
			}
		}

		if (passwordRef.current.value.length === 0) {
			errorMessages.password = strings.requiredField;
			if (!firstErrorField) {
				firstErrorField = passwordRef;
			}
		}

		return {errorMessages, firstErrorField};
	}

	const login = (event) => {
		event.preventDefault();
		setAlert("");
		setAlertType("");
		trimFields();

		const {errorMessages, firstErrorField} = formValidation();
		if (Object.keys(errorMessages).length > 0) {
			(firstErrorField ? firstErrorField : emailRef).current.focus();
			setErrors(errorMessages);
		} else {
			async function handleLogin() {
				// const recaptchaToken = await API.getReCAPTCHAToken(recaptchaRef);

				API.login(/*recaptchaToken, */emailRef.current.value, passwordRef.current.value)
					.then((result) => {
						if (result) {
							switch (result.statusCode) {
								case 201:
									setLoggedIn(true);
									break;
								default:
									setAlertType("danger");
									setAlert(strings.loginFailed);
									break;
							}
						}
					});
			}

			handleLogin();
		}
	};

	useEffect(() => {
		if (emailRef.current) {
			emailRef.current.focus();
		}
	}, []);

	return (
		<Container className="home-inner-container home-inner-container-size px-4 px-md-5 py-4 py-md-5">
			{
				(alert) &&
					<Row className="mb-3">
						<Col className="px-2 px-sm-4 px-xl-5">
							<Alert variant={alertType} className="mb-0 user-select-none transparent-cursor" dismissible={true} onClose={closeAlert}>{alert}</Alert>
						</Col>
					</Row>
			}
			<Row className="user-select-none transparent-cursor">
				<Col className="px-2 px-sm-4 px-xl-5">
					<span className="home-inner-container-title">{strings.logIn}</span><br />
					<span>{strings.logInToYourULOIAccount}</span>
				</Col>
			</Row>
			<Form className="mt-4 d-grid gap-3" onSubmit={login}>
				<Row>
					<Col className="px-2 px-sm-4 px-xl-5">
						<Form.Group>
							<Form.Control type="text" id="login-email" className={`${(errors.email) ? "error-border" : ""}`} placeholder={`${strings.email}*`} ref={emailRef} onChange={() => setErrors({...errors, email: false})} />
							{(errors.email) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="login-email">{errors.email}</Form.Label>}
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col className="px-2 px-sm-4 px-xl-5">
						<Form.Group className="password-field">
							<Form.Control type={showPassword ? "text" : "password"} id="login-password" className={`${(errors.password) ? "error-border" : ""}`} placeholder={`${strings.password}*`} ref={passwordRef} onChange={() => setErrors({...errors, password: false})} />
							<img alt={showPassword ? strings.hide : strings.show} onClick={toggleShowPassword} src={showPassword ? "/images/eye_slashed.svg" : "/images/eye.svg"} />
							{(errors.password) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="login-password">{errors.password}</Form.Label>}
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col className="px-2 px-sm-4 px-xl-5">
						<Button type="submit" className="w-100">{strings.logIn}</Button>
					</Col>
				</Row>
				{/* <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} size="invisible" /> */}
			</Form>
			<Row className="mt-3">
				<Col id="required-fields-text" className="px-2 px-sm-4 px-xl-5 user-select-none transparent-cursor">
					{strings.theFieldsMarkedWithAnAsteriskAreRequired}
				</Col>
			</Row>
			<Row className="mt-3">
				<Col className="px-2 px-sm-4 px-xl-5 user-select-none transparent-cursor">
					<Link id="forgot-password" to="/forgot-password">{strings.forgotPassword}</Link>
				</Col>
			</Row>
			<Row id="login-footer" className="mt-5 mb-3 user-select-none transparent-cursor">
				<Col className="px-2 px-sm-4 px-xl-5">
					<span>{strings.dontHaveAnAccountYet}</span><br />
					<Link to="/signup">{strings.signUpNow}</Link>
				</Col>
			</Row>
		</Container>
	);
};

export default Login;
