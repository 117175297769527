import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faDownload } from "@fortawesome/free-solid-svg-icons";
import Separator from "./Separator";
import strings from "../strings";
import "./Help.css";

const faqs = [
	{
		question: strings.faq00_question,
		answer: strings.faq00_answer
	},
	{
		question: strings.faq01_question,
		answer: strings.faq01_answer
	},
	{
		question: strings.faq02_question,
		answer: strings.faq02_answer
	},
	{
		question: strings.faq03_question,
		answer: strings.faq03_answer
	},
	{
		question: strings.faq04_question,
		answer: strings.faq04_answer
	},
	{
		question: strings.faq05_question,
		answer: strings.faq05_answer
	},
	{
		question: strings.faq06_question,
		answer: strings.faq06_answer
	},
	{
		question: strings.faq07_question,
		answer: strings.faq07_answer
	},
	{
		question: strings.faq08_question,
		answer: strings.faq08_answer
	},
	{
		question: strings.faq09_question,
		answer: strings.faq09_answer
	}
];

function Help() {
	const goUp = () => {
		const topContainer = document.getElementsByTagName("body")[0];
		if (topContainer) {
			topContainer.scrollIntoView({ behavior: "smooth" });
		}
	};

	const showVideo = () => {
		const videoPlayButton = document.getElementById("video-play-button");
		if (videoPlayButton) {
			videoPlayButton.style.display = "none";
		}
	};

	const downloadTutorial = () => {
		const link = document.createElement("a");
		link.href = strings.tutorialPath;
		link.download = strings.tutorialName; // Necessary
		link.style.display = "none";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Container fluid className="text-white">
			<Row>
				<Col className="uloi-title user-select-none transparent-cursor text-center">{strings.hiHowCanWeHelpYou}</Col>
			</Row>

			<Row className="mt-4">
				<Col className="text-center user-select-none transparent-cursor">
					<a className="button-link me-2" href="#tutorial">{strings.tutorial}</a>
					<a className="button-link me-2" href="#handbook">{strings.handbook}</a>
					<a className="button-link mt-2 mt-sm-0" href="#faqs">{strings.faqs}</a>
				</Col>
			</Row>

			<Separator className="mt-5" />

			<Row id="help-inner-container" className="mx-auto mt-5">
				<Col>
					<Row id="tutorial" className="help-section-title">
						<Col className="user-select-none transparent-cursor text-center">{strings.tutorial}</Col>
					</Row>
					<Row className="mt-3">
						<Col className="text-center p-0 position-relative user-select-none transparent-cursor">
							<iframe id="video-box" src={strings.youtubeTutorialURL} title={strings.youtubeTutorialTitle} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
							<Button type="button" id="video-play-button" onClick={showVideo}><img alt="" src="/images/play.svg" /></Button>
						</Col>
					</Row>

					<Row id="handbook" className="mt-5 justify-content-between">
						<Col className="help-box col-12 col-lg-5_7 px-4 px-sm-5 py-3 py-sm-4 d-flex flex-column justify-content-between align-items-start">
							<div className="user-select-none transparent-cursor">
								<span className="help-section-title">{strings.downloadULOIHandbook}</span>
								<p className="mt-2">{strings.discoverHowToMakeTheMostOfULOI}</p>
							</div>
							<div className="d-flex justify-content-center justify-content-lg-start w-100">
								<Button type="button" className="home-button mt-3" onClick={downloadTutorial}>
									<FontAwesomeIcon icon={faDownload} id="download-icon" className="me-1" />
									{strings.downloadNow}
								</Button>
							</div>
						</Col>

						<Col className="help-box col-12 col-lg-5_7 mt-5 mt-lg-0 px-4 px-sm-5 py-3 py-sm-4 d-flex flex-column justify-content-between align-items-start">
							<div className="user-select-none transparent-cursor">
								<span className="help-section-title user-select-none transparent-cursor">{strings.checkTheTechnicalDocumentationOnPostman}</span>
								<p className="mt-2">{strings.forTheLatestUpdatesAccessTheOfficialDocumentation}</p>
							</div>
							<div className="d-flex justify-content-center justify-content-lg-start w-100">
								<Link className="home-button mt-3" to={strings.postmanDocumentationURL}>
									<span className="align-middle">{strings.checkItOutNow}</span>
								</Link>
							</div>
						</Col>
					</Row>
				
					<Row className="mt-5">
						<Col className="help-box px-4 px-sm-5 py-3 py-sm-4 d-flex flex-column justify-content-center align-items-start" id="help-box-tech-support">
							<div className="user-select-none transparent-cursor">
								<span className="help-section-title user-select-none transparent-cursor">{strings.didntYoufindWhatYouWereLookingFor}</span>
								<p className="mt-2">{strings.contactUsWeWillBeHappyToHelpYou}</p>
							</div>
							<div className="d-flex justify-content-center justify-content-lg-start w-100">
								<HashLink className="home-button mt-3" to="/#contacts">
									<img className="me-1" alt="" src="/images/support.svg" />
									<span className="align-middle">{strings.contactUs}</span>
								</HashLink>
							</div>
						</Col>
					</Row>

					<Row className="mt-5" id="faqs">
						<Col className="help-box px-4 px-sm-5 py-3 py-sm-4">
							<Row className="mt-2 mx-0">
								<Col className="text-center user-select-none transparent-cursor">
									<span id="faq-icon">
										<img className="me-2" alt="" src="/images/faq.svg" />
										FAQ
									</span>
								</Col>
							</Row>
							<Row className="mt-3 mx-0">
								<Col className="help-section-title text-center user-select-none transparent-cursor">{strings.faqs}</Col>
							</Row>
							<Row className="mt-2 mx-0">
								<Col className="px-0">
									<Accordion flush>
									{
										faqs.map((faq, index) =>
											<Accordion.Item key={index} eventKey={index} className="mt-3">
												<Accordion.Header>
													{faq.question}
													<FontAwesomeIcon icon={faChevronDown} className="accordion-chevron ms-2" />
												</Accordion.Header>
												<Accordion.Body>
													<div className="accordion-separator"></div>
													{faq.answer}
												</Accordion.Body>
											</Accordion.Item>
										)
									}
									</Accordion>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>

			<img id="up-button" className="d-none" alt="˄" src="/images/up.svg" onClick={goUp} />
		</Container>
	);
}

export default Help;
