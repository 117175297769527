import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { SessionContext } from "../App";
import API from "../API";
// import ReCAPTCHA from "react-google-recaptcha";
import Utils from "../Utils";
import strings from "../strings";
import "./Signup.css";

function Signup() {
	const nameRef = useRef("");
	const emailRef = useRef("");
	const passwordRef = useRef("");
	const confirmPasswordRef = useRef("");
	// const recaptchaRef = useRef();
	const [errors, setErrors] = useState({});
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [showSignupModal, setShowSignupModal] = useState(false);
	const [signupModalAlt, setSignupModalAlt] = useState("");
	const [signupModalIcon, setSignupModalIcon] = useState("");
	const [signupModalMessage, setSignupModalMessage] = useState("");
	const [latestStatusCode, setLatestStatusCode] = useState(0);
	// const setLoggedIn = useContext(SessionContext);

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
		passwordRef.current.focus();
	};

	const toggleShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
		confirmPasswordRef.current.focus();
	};

	const trimFields = () => {
		nameRef.current.value = nameRef.current.value.trim();
		emailRef.current.value = emailRef.current.value.trim();
		passwordRef.current.value = passwordRef.current.value.trim();
		confirmPasswordRef.current.value = confirmPasswordRef.current.value.trim();
	}

	const formValidation = () => {
		let errorMessages = {};
		let firstErrorField = null;

		if (nameRef.current.value.length === 0) {
			errorMessages.name = strings.requiredField;
			firstErrorField = nameRef;
		}

		if (emailRef.current.value.length === 0) {
			errorMessages.email = strings.requiredField;
			if (!firstErrorField) {
				firstErrorField = emailRef;
			}
		}

		if (emailRef.current.value.length > 0 && !Utils.isEmailValid(emailRef.current.value)) {
			errorMessages.email = strings.invalidEmailFormat;
			if (!firstErrorField) {
				firstErrorField = emailRef;
			}
		}

		if (passwordRef.current.value.length === 0) {
			errorMessages.password = strings.requiredField;
			if (!firstErrorField) {
				firstErrorField = passwordRef;
			}
		} else if (!Utils.isPasswordStrong(passwordRef.current.value)) {
			errorMessages.password = strings.passwordIsTooWeak;
			if (!firstErrorField) {
				firstErrorField = passwordRef;
			}
		}

		if (confirmPasswordRef.current.value.length === 0) {
			errorMessages.confirmPassword = strings.requiredField;
			if (!firstErrorField) {
				firstErrorField = confirmPasswordRef;
			}
		} else if (confirmPasswordRef.current.value !== passwordRef.current.value) {
			errorMessages.confirmPassword = strings.passwordsDontMatch;
			if (!firstErrorField) {
				firstErrorField = confirmPasswordRef;
			}
		}

		return {errorMessages, firstErrorField};
	};

	const signup = async (event) => {
		event.preventDefault();
		trimFields();

		const {errorMessages, firstErrorField} = formValidation();
		if (Object.keys(errorMessages).length > 0) {
			(firstErrorField ? firstErrorField : nameRef).current.focus();
			setErrors(errorMessages);
		} else {
			// const recaptchaToken = API.getReCAPTCHAToken(recaptchaRef);

			API.signUp(/*recaptchaToken, */nameRef.current.value, emailRef.current.value, passwordRef.current.value)
				.then((result) => {
					if (result) {
						switch (result.statusCode) {
							case 201:
								setSignupModalAlt(strings.success);
								setSignupModalIcon("/images/success.svg");
								setSignupModalMessage(strings.signupSuccessfulText);
								break;
							case 400:
							case 422:
								setSignupModalAlt(strings.warning);
								setSignupModalIcon("/images/warning.svg");
								setSignupModalMessage(strings.signupUnsuccessfulText);
								break;
							case 409:
								setSignupModalAlt(strings.warning);
								setSignupModalIcon("/images/warning.svg");
								setSignupModalMessage(strings.signupAlreadyRegisteredText);
								break;
							case 500:
								setSignupModalAlt(strings.error);
								setSignupModalIcon("/images/error.svg");
								setSignupModalMessage(strings.serverError);
								break;
							default:
						}
						setLatestStatusCode(result.statusCode);
						setShowSignupModal(true);
					}
				});
		}
	};

	const onClickOK = () => {
		if (latestStatusCode === 201) {
		// 	API.login(emailRef.current.value, passwordRef.current.value)
		// 		.then(() => setLoggedIn(true));
			nameRef.current.value = "";
			emailRef.current.value = "";
			passwordRef.current.value = "";
			confirmPasswordRef.current.value = "";
		}

		setShowSignupModal(false);
	};

	useEffect(() => {
		nameRef.current.focus();
	}, []);

	return (
		<>
			<Container className="home-inner-container home-inner-container-size px-4 px-md-5 py-4 py-md-5">
				<Row className="user-select-none transparent-cursor">
					<Col className="px-2 px-sm-4 px-xl-5">
						<span className="home-inner-container-title">{strings.signUp}</span><br />
						<span>{strings.fillInAllFieldsCorrectly}</span>
					</Col>
				</Row>
				<Form className="mt-4 d-grid gap-3" onSubmit={signup} autoComplete="off">
					<Row>
						<Col className="px-2 px-sm-4 px-xl-5">
							<Form.Group>
								<Form.Control type="text" id="signup-user-name" className={`${(errors.name) ? "error-border" : ""}`} placeholder={`${strings.name}*`} ref={nameRef} onChange={() => setErrors({...errors, name: false})} />
								{(errors.name) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="signup-user-name">{errors.name}</Form.Label>}
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col className="px-2 px-sm-4 px-xl-5">
							<Form.Group>
								<Form.Control type="text" id="signup-email" className={`${(errors.email) ? "error-border" : ""}`} placeholder={`${strings.email}*`} ref={emailRef} onChange={() => setErrors({...errors, email: false})} />
								{(errors.email) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="signup-email">{errors.email}</Form.Label>}
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col className="px-2 ps-sm-4 ps-xl-5 pe-sm-4 pe-md-2 col-12 col-md-6">
							<Form.Group className="password-field">
								<Form.Control type={showPassword ? "text" : "password"} id="signup-password" className={`${(errors.password) ? "error-border" : ""}`} autoComplete="new-password" placeholder={`${strings.password}*`} ref={passwordRef} onChange={() => setErrors({...errors, password: false})} />
								<img alt={showPassword ? strings.hide : strings.show} onClick={toggleShowPassword} src={showPassword ? "/images/eye_slashed.svg" : "/images/eye.svg"} />
								{
									(errors.password) &&
										<>
											<div id="password-tooltip" className="user-select-none transparent-cursor" dangerouslySetInnerHTML={{__html: strings.passwordRequirementsText}}></div>
											<Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="signup-password">{errors.password}</Form.Label>
										</>
								}
							</Form.Group>
						</Col>
						<Col className="px-2 ps-sm-4 ps-md-2 pe-sm-4 pe-xl-5 mt-3 mt-md-0 col-12 col-md-6">
							<Form.Group className="password-field">
								<Form.Control type={showConfirmPassword ? "text" : "password"} id="signup-confirm-password" className={`${(errors.confirmPassword) ? "error-border" : ""}`} autoComplete="new-password" placeholder={`${strings.confirmPassword}*`} ref={confirmPasswordRef} onChange={() => setErrors({...errors, confirmPassword: false})} />
								<img alt={showConfirmPassword ? strings.hide : strings.show} onClick={toggleShowConfirmPassword} src={showConfirmPassword ? "/images/eye_slashed.svg" : "/images/eye.svg"} />
								{(errors.confirmPassword) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="signup-confirm-password">{errors.confirmPassword}</Form.Label>}
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col className="px-2 px-sm-4 px-xl-5">
							<Button type="submit" className="w-100">{strings.submit}</Button>
						</Col>
					</Row>
					{/* <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} size="invisible" /> */}
				</Form>
				<Row className="mt-3">
					<Col id="required-fields-text" className="px-2 px-sm-4 px-xl-5 user-select-none transparent-cursor">
						{strings.theFieldsMarkedWithAnAsteriskAreRequired}
					</Col>
				</Row>
				<Row id="signup-footer" className="mt-5 mb-3 user-select-none transparent-cursor">
					<Col className="px-2 px-sm-4 px-xl-5">
						<span>{strings.alreadyHaveAnAccount}</span><br />
						<Link to="/login">{strings.logIn}</Link>
					</Col>
				</Row>
			</Container>

			<Modal id="signup-modal" show={showSignupModal}>
				<Modal.Body>
					<img alt={signupModalAlt} src={signupModalIcon} />
					<p className="mt-2 user-select-none transparent-cursor" dangerouslySetInnerHTML={{__html: signupModalMessage}}></p>
					<Button type="button" onClick={onClickOK}>{strings.ok}</Button>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default Signup;
