import { Col, Container, Row } from "react-bootstrap";
import { Outlet, useLocation } from "react-router-dom";
import HomeFooter from "../components/HomeFooter";
import HomeMenu from "../components/HomeMenu";
import "./Home.css";

function Home() {
	const location = useLocation();

	return (
		<Container fluid id="home-background" className="d-flex flex-column min-vh-100 px-4 px-sm-5 py-2 py-sm-4">
			<Row id="home-outer-container" className="flex-grow-1 px-1 px-sm-3 px-lg-4 px-xl-5">
				<Col className="d-flex flex-column">
					<Row>
						<Col>
							<HomeMenu
								showLinks={location.pathname === "/"}
								showLogIn={location.pathname !== "/login"}
								showSignUp={location.pathname !== "/signup"} />
						</Col>
					</Row>
					<Row className="flex-grow-1 my-5 align-items-center">
						<Col>
							<Outlet />
						</Col>
					</Row>
					<Row>
						<Col>
							<HomeFooter />
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
}

export default Home;
