import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import API from "../API";
import ConfirmationBox from "./ConfirmationBox";
import EditLMS from "./EditLMS";
import { SessionContext } from "../App";
import strings from "../strings";
import "./LMSCard.css";

function LMSCard({id, name, url, type, adminID, adminLabel, colourIndex, dashboard}) {
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const navigate = useNavigate();
	const session = API.getSession();
	const role = (session && session.role) ? session.role : "";
	const setLoggedIn = useContext(SessionContext);
	const showUsers = () => navigate(`/lms/${id}`);

	const logout = () => API.logout().then(() => setLoggedIn(false));

	const remove = () => {
		API.deleteLMS(id)
			.then((result) => {
				if (result) {
					switch (result.statusCode) {
						case 200:
							if (dashboard) {
								dashboard.setLMSCards(dashboard.lmsCards.filter((lmsCard) => (lmsCard.id !== id)));
							}
							break;
						case 401:
							logout();
							break;
						case 403:
							dashboard.setAlertType("danger");
							dashboard.setAlert(strings.unauthorisedRequest);
							break;
						case 400:
						case 404:
						case 422:
							dashboard.setAlertType("warning");
							dashboard.setAlert(strings.requestFailed);
							break;
						case 500:
							dashboard.setAlertType("danger");
							dashboard.setAlert(strings.serverError);
							break;
						default:
					}
				}
			});
	}

	return (
		<>
			<Container className={`lms-card border-left-${colourIndex}`}>
				<Row>
					<Col className="lms-name user-select-none transparent-cursor truncated-text">{name}</Col>
				</Row>
				<Row className="mt-2">
					<Col className="lms-body truncated-text">
						<span className="user-select-none transparent-cursor">{strings.url}: </span><span className="user-select-all">{url}</span><br />
						<span className="user-select-none transparent-cursor">{strings.type}: </span><span className="user-select-all">{(type === "XAPI") ? strings.xapi : strings.scorm}</span><br />
						<span className="user-select-none transparent-cursor">{strings.lmsID}: </span><span className="user-select-all">{id}</span>
						{
							(role === "superadmin") ?
								<>
									<br />
									<span className="user-select-none transparent-cursor">{strings.administrator}: </span>
									<span className="user-select-all">{adminLabel}</span>
								</>
							:
								<>&nbsp;</>
						}
					</Col>
				</Row>
				<Row className="mt-2">
					<Col>
						<Button type="button" className="standard-dark-button show-users-button" onClick={showUsers}>{strings.showUsers}</Button>
					</Col>
				</Row>

				<Dropdown className="lms-dropdown">
					<Dropdown.Toggle>
						<img alt="…" src="/images/3dots.svg" />
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item className="px-3" onClick={() => setShowEditModal(true)}>{strings.edit}</Dropdown.Item>
						<Dropdown.Item className="px-3" onClick={() => setShowDeleteModal(true)}>{strings.delete}</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Container>

			<EditLMS show={showEditModal} setShow={setShowEditModal} id={id} name={name} type={type} adminID={adminID} url={url} dashboard={dashboard} />
			<ConfirmationBox show={showDeleteModal} setShow={setShowDeleteModal} action={remove} />
		</>
	);
}

export default LMSCard;
