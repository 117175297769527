import { jwtDecode } from "jwt-decode";
import { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import API from "../API";
// import ReCAPTCHA from "react-google-recaptcha";
import Utils from "../Utils";
import strings from "../strings";
import "./ResetPassword.css";

function ResetPassword() {
	const {token} = useParams();
	const passwordRef = useRef();
	const confirmPasswordRef = useRef();
	// const recaptchaRef = useRef();
	const [adminID, setAdminID] = useState(0);
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState({});
	const [alert, setAlert] = useState("");
	const [alertType, setAlertType] = useState("");
	const [errors, setErrors] = useState({});
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const closeAlert = () => {
		setAlert("");
		setAlertType("");

		if (passwordRef.current) {
			passwordRef.current.focus();
		}
	};

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
		passwordRef.current.focus();
	};

	const toggleShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
		confirmPasswordRef.current.focus();
	};

	const trimFields = () => {
		passwordRef.current.value = passwordRef.current.value.trim();
		confirmPasswordRef.current.value = confirmPasswordRef.current.value.trim();
	};

	const formValidation = () => {
		let errorMessages = {};
		let firstErrorField = null;

		if (passwordRef.current.value.length === 0) {
			errorMessages.password = strings.requiredField;
			if (!firstErrorField) {
				firstErrorField = passwordRef;
			}
		}

		if (passwordRef.current.value.length > 0 && !Utils.isPasswordStrong(passwordRef.current.value)) {
			errorMessages.password = strings.passwordIsTooWeak;
			if (!firstErrorField) {
				firstErrorField = passwordRef;
			}
		}

		if (confirmPasswordRef.current.value.length === 0) {
			errorMessages.confirmPassword = strings.requiredField;
			if (!firstErrorField) {
				firstErrorField = confirmPasswordRef;
			}
		}
		
		if (confirmPasswordRef.current.value !== passwordRef.current.value) {
			errorMessages.confirmPassword = strings.passwordsDontMatch;
			if (!firstErrorField) {
				firstErrorField = confirmPasswordRef;
			}
		}

		return {errorMessages, firstErrorField};
	}

	const resetPassword = (event) => {
		event.preventDefault();
		setAlert("");
		setAlertType("");
		trimFields();

		const {errorMessages, firstErrorField} = formValidation();
		if (Object.keys(errorMessages).length > 0) {
			(firstErrorField ? firstErrorField : passwordRef).current.focus();
			setErrors(errorMessages);
		} else {
			// const recaptchaToken = API.getReCAPTCHAToken(recaptchaRef);

			API.resetPassword(/*recaptchaToken, */adminID, email, passwordRef.current.value, token)
				.then((result) => {
					if (result) {
						switch (result.statusCode) {
							case 201:
								setMessage({text: strings.yourPasswordHasBeenResetSuccessfully, error: false});
								break;
							case 400:
							case 404:
							case 422:
								setAlertType("warning");
								setAlert(strings.requestFailed);
								break;
							case 403:
								setAlertType("danger");
								setAlert(strings.unauthorisedRequest);
								break;
							case 410:
								setMessage({text: strings.linkExpired, error: true});
								break;
							case 500:
								setAlertType("danger");
								setAlert(strings.serverError);
								break;
							default:
						}
					}

					if (passwordRef.current) {
						passwordRef.current.focus();
					}
				});
		}
	};

	useEffect(() => {
		if (token) {
			const now = new Date().getTime();
			const {id, email, exp} = jwtDecode(token);

			if (!id || !email || !exp ||
				!Number(id) || typeof email !== "string" || !Number(exp) ||
				!Utils.isEmailValid(email)) {
				setMessage({text: strings.invalidLink, error: true});
			} else if (((exp * 1000) - now) < 0) {
				setMessage({text: strings.linkExpired, error: true});
			} else {
				setAdminID(id);
				setEmail(email);

				if (passwordRef.current) {
					passwordRef.current.focus();
				}
			}
		} else {
			setMessage({text: strings.invalidLink, error: true});
		}
	}, []);

	return (
		<Container className="home-inner-container home-inner-container-size px-4 px-md-5 py-4 py-md-5">
			{
				(alert) &&
					<Row className="mb-3">
						<Col className="px-2 px-md-4 px-xl-5">
							<Alert variant={alertType} className="mb-0 user-select-none transparent-cursor" dismissible={true} onClose={closeAlert}>{alert}</Alert>
						</Col>
					</Row>
			}
			<Row className="user-select-none transparent-cursor">
				<Col className="px-2 px-md-4 px-xl-5">
					<span className="home-inner-container-title">{strings.resetPassword}</span><br />
					{(!message && email) && <span>{strings.formatString(strings.enterANewPasswordForX, email)}</span>}
				</Col>
			</Row>
			{
				(message && message.text) ?
					<Row>
						<Col id="reset-password-message" className={`mt-4 px-2 px-md-4 px-xl-5 user-select-none transparent-cursor ${(message.error) ? "error-message" : "success-message"}`}>
							{message.text}
						</Col>
					</Row>
				:
					<Form className="mt-4 d-grid gap-3" onSubmit={resetPassword}>
						<Row>
							<Col className="px-2 px-md-4 px-xl-5">
								<Form.Group className="password-field">
									<Form.Control type={showPassword ? "text" : "password"} id="reset-password-password" className={`${(errors.password) ? "error-border" : ""}`} autoComplete="new-password" placeholder={`${strings.password}*`} ref={passwordRef} onChange={() => setErrors({...errors, password: false})} />
									<img alt={showPassword ? strings.hide : strings.show} onClick={toggleShowPassword} src={showPassword ? "/images/eye_slashed.svg" : "/images/eye.svg"} />
									{(errors.password) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="reset-password-password">{errors.password}</Form.Label>}
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col className="px-2 px-md-4 px-xl-5">
								<Form.Group className="password-field">
									<Form.Control type={showConfirmPassword ? "text" : "password"} id="reset-password-confirm-password" className={`${(errors.confirmPassword) ? "error-border" : ""}`} autoComplete="new-password" placeholder={`${strings.confirmPassword}*`} ref={confirmPasswordRef} onChange={() => setErrors({...errors, confirmPassword: false})} />
									<img alt={showConfirmPassword ? strings.hide : strings.show} onClick={toggleShowConfirmPassword} src={showConfirmPassword ? "/images/eye_slashed.svg" : "/images/eye.svg"} />
									{(errors.confirmPassword) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="reset-password-confirm-password">{errors.confirmPassword}</Form.Label>}
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col className="px-2 px-md-4 px-xl-5">
								<Button type="submit" className="w-100">{strings.resetPassword}</Button>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col id="required-fields-text" className="px-2 px-sm-4 px-xl-5 user-select-none transparent-cursor">
								{strings.theFieldsMarkedWithAnAsteriskAreRequired}
							</Col>
						</Row>
						{/* <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} size="invisible" /> */}
					</Form>
			}
		</Container>
	);
};

export default ResetPassword;
