import { createContext, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import API from "./API";
import Account from "./pages/Account";
import Admins from "./components/Admins";
import Dashboard from "./components/Dashboard";
import ForgotPassword from "./components/ForgotPassword";
import Help from "./components/Help";
import Home from "./pages/Home";
import Landing from "./components/Landing";
import Login from "./components/Login";
import NotFound from "./components/NotFound";
import ResetPassword from "./components/ResetPassword";
import Settings from "./components/Settings";
import ShowUsers from "./components/ShowUsers";
import Signup from "./components/Signup";

const SessionContext = createContext();

function App() {
	const [loggedIn, setLoggedIn] = useState(API.isLoggedIn);
	const session = API.getSession();
	const role = (session && session.role) ? session.role : "";

	return (
		<SessionContext.Provider value={setLoggedIn}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={(loggedIn) ? <Account /> : <Home />}>
						<Route index element={(loggedIn) ? <Dashboard redirect={true} /> : <Landing />} />
						<Route path="login" element={(loggedIn) ? <Dashboard redirect={true} /> : <Login />} />
						<Route path="signup" element={(loggedIn) ? <Dashboard redirect={true} /> : <Signup />} />
						<Route path="forgot-password" element={(loggedIn) ? <Dashboard redirect={true} /> : <ForgotPassword />} />
						<Route path="reset-password" element={(loggedIn) ? <Dashboard redirect={true} /> : <Landing redirect={true} />} />
						<Route path="reset-password/:token" element={(loggedIn) ? <Dashboard redirect={true} /> : <ResetPassword />} />
						<Route path="help" element={(loggedIn) ? <Dashboard redirect={true} /> : <Help />} />
						<Route path="dashboard" element={(loggedIn) ? <Dashboard /> : <Landing redirect={true} />} />
						<Route path="admins" element={(loggedIn) ? ((role === "superadmin") ? <Admins /> : <Dashboard redirect={true} />) : <Landing redirect={true} />} />
						<Route path="settings" element={(loggedIn) ? <Settings /> : <Landing redirect={true} />} />
						<Route path="lms" element={(loggedIn) ? <Dashboard redirect={true} /> : <Landing redirect={true} />} />
						<Route path="lms/:lmsID" element={(loggedIn) ? <ShowUsers /> : <Landing redirect={true} />} />
						<Route path="*" element={(loggedIn) ? <Dashboard redirect={true} /> : <NotFound />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</SessionContext.Provider>
	);
};

export default App;
export { SessionContext };
