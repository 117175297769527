import { Col, Row } from "react-bootstrap";
import "./Separator.css";

export default function Separator({ className }) {
	return (
		<Row className={`separator ${className}`}>
			<Col className="d-flex justify-content-end"><img alt="" className="w-75 w-sm-50" src="/images/line_left_white.svg" /></Col>
			<Col className="px-1"><img alt="" src="/images/wheel_white.svg" /></Col>
			<Col className="d-flex justify-content-start"><img alt="" className="w-75 w-sm-50" src="/images/line_right_white.svg" /></Col>
		</Row>
	);
};
