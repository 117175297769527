import { Col, Container, Row } from "react-bootstrap";
import strings from "../strings";
import "./HomeFooter.css";

const iconLinks = [
	{
		alt: "WhatsApp",
		path: "/images/whatsapp.svg",
		url: strings.whatsappURL
	},
	{
		alt: "Facebook",
		path: "/images/facebook.svg",
		url: strings.facebookURL
	},
	{
		alt: "LinkedIn",
		path: "/images/linkedin.svg",
		url: strings.linkedInURL
	},
	{
		alt: "YouTube",
		path: "/images/youtube.svg",
		url: strings.youtubeURL
	},
	{
		alt: "Flipboard",
		path: "/images/flipboard.svg",
		url: strings.flipboardURL
	},
	{
		alt: "Google News",
		path: "/images/google_news.svg",
		url: strings.googleNewsURL
	},
	{
		alt: "Company profile",
		path: "/images/pdf.svg",
		url: strings.companyProfileURL
	},
];

const textLinks = [
	{
		text: strings.privacyPolicy,
		url: strings.privacyPolicyURL
	},
	{
		text: strings.cookiePolicy,
		url: strings.cookiePolicyURL
	}
];

function HomeFooter() {
	return (
		<Container id="home-footer" className="d-grid gap-4 gap-sm-3 mb-5">
			<Row>
				<Col><img src="/images/separator_large.svg" alt="" /></Col>
			</Row>
			<Row>
				<Col className="user-select-none transparent-cursor" dangerouslySetInnerHTML={{__html: `<span class="text-nowrap">${strings.formatString(strings.companyYears, (new Date()).getFullYear())}</span> - <span class="text-nowrap">${strings.allRightsAreReserved}</span> - <span class="text-nowrap">${strings.vatNoX}</span> - <span class="text-nowrap">${strings.createdByCompany}</span>`}}></Col>
			</Row>
			<Row>
				<Col className="px-0">
				{
					iconLinks.map((iconLink, index) => (
						<a key={index} className="mx-2 mx-sm-3" href={iconLink.url} target="_blank" rel="noreferrer"><img src={iconLink.path} alt={iconLink.alt} /></a>
					))
				}
				</Col>
			</Row>
			<Row>
				<Col>
				{
					textLinks.map((textLink, index) => (
						<a key={index} className="mx-3 user-select-none transparent-cursor" href={textLink.url} target="_blank" rel="noreferrer">{textLink.text}</a>
					))
				}
				</Col>
			</Row>
			<Row>
				<Col><img src="/images/separator_small.svg" alt="" /></Col>
			</Row>
			<Row>
				<Col className="user-select-none transparent-cursor" dangerouslySetInnerHTML={{__html: strings.formatString(strings.readOurReviews, `<a href="${strings.trustpilotURL}" target="_blank" rel="noreferrer"><img id="trustpilot-icon" alt="Trustpilot" src="/images/trustpilot.svg" /></a>`)}}></Col>
			</Row>
		</Container>
	)
};

export default HomeFooter;
